/* CSS Reset */
* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  font-family: "Raleway", sans-serif;
}

html,
body,
#root {
  height: 100%;
}

p, li {
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin: 1rem;
}
